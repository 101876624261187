import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { Dictionary } from '../shared/data.structures';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private _componentState: Subject<SearchComponentState> = new Subject();

  constructor(private http: HttpClient) {}

  get componentState(): Observable<SearchComponentState> {
    return this._componentState.asObservable();
  }

  setComponentState(state: SearchComponentState) {
    this._componentState.next(state);
  }

  go(
    term: string,
    includeInactive: boolean = false,
    includeFpsOnly: boolean = false
  ): Observable<Dictionary<ApolloSearchResults>> {
    const encodedTerm = encodeURIComponent(term.trim());
    return this.http
      .get<Record<number, ApolloSearchResults>>(
        `/api/search/${encodedTerm}?includeInactiveResults=${includeInactive}&includeFpsOnly=${includeFpsOnly}`
      )
      .pipe(
        map((res: Record<number, ApolloSearchResults>) => {
          const results = new Dictionary<ApolloSearchResults>();

          // map raw data into our dictionary instance
          for (const [clientId, value] of Object.entries(res)) {
            results.addOrUpdate(clientId, value);
          }

          return results;
        })
      );
  }
}

export class SearchResult {
  Name: string;
  Id: string;
  IsActive: boolean;
  Lineage: SearchResult[];
  ClientId: number;

  isSelected = false;
}

// strongly typed results for use in global search view
export class ApolloSearchResults {
  Communities: SearchResult[] = new Array<SearchResult>();
  Buildings: SearchResult[] = new Array<SearchResult>();
  FloorPlans: SearchResult[] = new Array<SearchResult>();
  Neighborhoods: SearchResult[] = new Array<SearchResult>();
  Units: SearchResult[] = new Array<SearchResult>();

  get allResults(): SearchResult[] {
    return this.Communities.concat(this.Buildings, this.FloorPlans, this.Neighborhoods, this.Units);
  }
}

export class SearchComponentState {
  constructor(
    public isOpen: boolean = false,
    public targetedComponent: SearchComponentTypes,
    public inSelectionMode?: boolean,
    public topTitle?: string,
    public singleSelectionMode?: boolean,
    public searchResults?: SearchResult[]
  ) {}
}

export class ResultSet {
  ClientId: number;
  ClientName: string;
  Results: ApolloSearchResults;

  constructor(clientId: number, clientName: string, results: ApolloSearchResults) {
    this.ClientId = clientId;
    this.ClientName = clientName;
    this.Results = results;
  }
}

export enum SearchComponentTypes {
  Global,
  Floorplans
}
